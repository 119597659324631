import DocumentPage from "../components/pdf/DocumentPage";
import TableTemplate from "../components/pdf/TableTemplate";
import { View, Text } from "@react-pdf/renderer";
import { printPriceWithCommasAndPeriods } from "../utils/functions";

const SaleByOrderWithMessagingReport = ({ data, totalsByCurrency }: any) => {

    return (
        <DocumentPage orientation={"landscape"}>

            <View>
                <Text>Reporte de ingresos por mensajería</Text>
                <Text> </Text>
            </View>

            <View>
                <TableTemplate
                    data={data || []}
                />
            </View>

            <View style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Text style={{fontSize: 10}}>Totales</Text>
                <Text style={{fontSize: 10}}> {totalsByCurrency.map((elem: { amount: any; currency: string; }) => {
                    return (
                        printPriceWithCommasAndPeriods(elem?.amount) + " " + elem?.currency
                    )
                }).join(", ")}</Text>
            </View>

        </DocumentPage>
    )
}

export default SaleByOrderWithMessagingReport
