import React, { useEffect, useState } from "react";

// Lista de nombres de archivos SVG en la carpeta src/assets/svgs
const svgFiles = [
  "noData2.svg",
  "noData3.svg",
  "noData4.svg",
  "noData5.svg",
  "noData6.svg",
  "noData7.svg",
  "noData8.svg",
  "noData9.svg",
  "noData10.svg",
  "noData11.svg",
];

interface Empty {
  title?: string;
  subTitle?: string;
  size?: "xs" | "s" | "m" | "l";
}

const EmptyList = ({ title, subTitle, size }: Empty) => {
  const [svg, setSvg] = useState<string | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    // Seleccionar un archivo SVG aleatorio
    const randomIndex = Math.floor(Math.random() * svgFiles.length);
    const selectedSvg = svgFiles[randomIndex];
    setSvg(selectedSvg);
    setTimeout(() => {
      setLoaded(true); // Indicar que la carga ha comenzado
    }, 100);
  }, []);

  if (!svg) {
    return <div>Cargando...</div>;
  }

  return (
    <div
      className={`flex h-auto flex-col min-h-0 flex-1 justify-center items-center ${
        loaded ? "opacity-100 transition-opacity duration-200" : "opacity-0"
      } `}
    >
      <div className="flex flex-shrink items-center justify-center min-h-0 max-w-24">
        <img
          src={`/${svg}`}
          alt="No hay datos svg"
          className="object-scale-down max-h-24"
        />
      </div>

      <p className="font-thin text-sm text-customBlue-500 py-2">
        {title ?? "No hay elementos que mostrar"}
      </p>
      {
        <p className="font-thin py-2 text-sm text-customBlue">
          {subTitle ?? ""}
        </p>
      }
    </div>
  );
};

export default EmptyList;
