import { useAppSelector } from "../../../../store/hooks";
import { useState } from "react";
import { exportExcel, generatePdf } from "../../../../utils/helpers";
import MultipleActBtn, { BtnActions } from "../../../../components/misc/MultipleActBtn";
import { FaRegFilePdf } from "react-icons/fa";
import Modal from "../../../../components/misc/GenericModal";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "../../../../components/forms/Input";
import Button from "../../../../components/misc/Button";
import { BsFiletypeXlsx } from "react-icons/bs";
import { printPriceWithCommasAndPeriods } from "../../../../utils/functions";
import SaleByOrderWithMessagingReport from "../../../../reports/SaleByOrderWithMessagingReport";
import { salesOrdersWithMessaging } from "../../../../interfaces/ServerInterfaces";


const PdfAndExcelMessagingIncomes = (props: any) => {

    const { salesbyOrdersWithMessaging } = props.dataAccess;

    const { titlesForExport } = useAppSelector((state) => state.nomenclator);

    const [exportModal, setExportModal] = useState(false);

    let data = salesbyOrdersWithMessaging?.map((elem: any) => {
        const totalsMap: { [currency: string]: number } = {};

        elem.shipping.forEach((shipping: { price: { codeCurrency: any; amount: any; }; }) => {
            const { codeCurrency, amount } = shipping.price;

            if (!!codeCurrency && !!amount) {
                if (!totalsMap[codeCurrency]) {
                    totalsMap[codeCurrency] = 0;
                }

                totalsMap[codeCurrency] += amount;
            }
        });

        const totals: { currency: string, amount: number }[] = Object.keys(totalsMap).map(currency => ({
            currency,
            amount: totalsMap[currency]
        }));


        let data = {
            'Nombre': elem?.name,
            'Correo electrónico': elem?.email,
            'Envío': totals.map(elem => {
                return (
                    printPriceWithCommasAndPeriods(elem?.amount) + " " + elem?.currency
                )
            }).join(", "),
        };
        return data;
    })

    if (data && Array.isArray(data) && titlesForExport) data = filtrarPropiedades(data, titlesForExport);

    let dataForExcel: any
    let codeGeneratorValues: any

    if (data && Array.isArray(data) && titlesForExport) {
        dataForExcel = procesarPropiedades(data);
        codeGeneratorValues = codeGenerator(dataForExcel);
    }

    const exportAction = (name: string) => {
        const dataToExport: Record<string, string | number>[] = codeGeneratorValues ?? [];
        exportExcel(dataToExport, name);
    };

    function calculateTotalsByCurrency(elem: salesOrdersWithMessaging[]): Total[] {
        const totalsMap: { [currency: string]: number } = {};

        elem.forEach(item => {
            item.shipping.forEach(shipping => {
                const { codeCurrency, amount } = shipping.price;

                if (codeCurrency && amount != null) {
                    if (!totalsMap[codeCurrency]) {
                        totalsMap[codeCurrency] = 0;
                    }

                    totalsMap[codeCurrency] += amount;
                }
            });
        });

        const totals: Total[] = Object.keys(totalsMap).map(currency => ({
            currency,
            amount: totalsMap[currency]
        }));

        return totals;
    }

    const totalsByCurrency: Total[] = calculateTotalsByCurrency(salesbyOrdersWithMessaging);


    const handleExport = () => {
        generatePdf(
            //@ts-ignore
            SaleByOrderWithMessagingReport({
                data,
                totalsByCurrency
            })
            ,
            "Reporte de ingresos de mensajería"
        )
    }
    const actions: BtnActions[] = [
        {
            title: "Exportar pdf",
            icon: <FaRegFilePdf className="h-5 text-gray-500" />,
            action: handleExport
        },
        {
            title: "Exportar excel",
            icon: <BsFiletypeXlsx className="h-5 text-gray-500" />,
            action: () => setExportModal(true)
        },
    ];

    return (
        <div className={props.show ? '' : 'hidden'}>
            <div className=" mb-2">
                <MultipleActBtn items={actions} />
            </div>

            {exportModal && (
                <Modal state={exportModal} close={setExportModal}>
                    <ExportModalContainer
                        exportAction={exportAction}
                        close={() => setExportModal(false)}
                    />
                </Modal>
            )}

        </div>
    )
}

export default PdfAndExcelMessagingIncomes

const ExportModalContainer = ({
    exportAction,
    close,
}: {
    exportAction: Function;
    close: Function;
}) => {
    const { control, handleSubmit } = useForm();
    const submit: SubmitHandler<Record<string, string>> = (data) => {
        exportAction(data.name);
        close();
    };
    return (
        <form onSubmit={handleSubmit(submit)}>
            <Input
                name="name"
                control={control}
                label="Nombre del archivo .xlsx"
                rules={{ required: "Requerido *" }}
            />
            <div className="flex justify-end py-2">
                <Button color="slate-600" name="Aceptar" type="submit" />
            </div>
        </form>
    );
};


export function filtrarPropiedades(objetos: any[], clavesPermitidas: string[]): any[] {

    return objetos.map(objeto => {
        const nuevoObjeto: any = {};

        clavesPermitidas.forEach(clave => {
            if (objeto.hasOwnProperty(clave)) {
                nuevoObjeto[clave] = objeto[clave];
            }
        });

        return nuevoObjeto;
    });
}

interface ObjetoConPropiedades {
    [key: string]: string | number; // Puedes ajustar el tipo según las propiedades específicas
}

export function procesarPropiedades(arrayDeObjetos: ObjetoConPropiedades[]): ObjetoConPropiedades[] {
    // Crear un nuevo array para almacenar los objetos modificados
    const nuevoArray: ObjetoConPropiedades[] = [];

    arrayDeObjetos.forEach(objeto => {
        // Clonar el objeto para no modificar el original
        const objetoModificado: ObjetoConPropiedades = { ...objeto };

        // Ordenar alfabéticamente las propiedades del objeto
        const propiedadesOrdenadas = Object.entries(objetoModificado)
            .sort(([a], [b]) => a.localeCompare(b));

        const objetoOrdenado: ObjetoConPropiedades = Object.fromEntries(propiedadesOrdenadas);

        // Buscar las propiedades Subtotal, Total y Total Pagado
        const propiedadesAsociadas = ['Subtotal', 'Total', 'Total Pagado'];

        // Crear un nuevo objeto con las propiedades ordenadas y las asociadas
        const nuevoObjeto: ObjetoConPropiedades = {};
        propiedadesOrdenadas.forEach(([clave, valor]) => {
            nuevoObjeto[clave] = valor;
            if (propiedadesAsociadas.includes(clave)) {
                // Si es una propiedad asociada, agregar la propiedad Code justo después
                nuevoObjeto[`${clave}-moneda`] = objetoOrdenado[`${clave}-moneda`];
            }
        });

        // Agregar el objeto modificado al nuevo array
        nuevoArray.push(nuevoObjeto);
    });

    // Devolver el nuevo array
    return nuevoArray;
}

export function codeGenerator(arrayDeObjetos: ObjetoConPropiedades[]): ObjetoConPropiedades[] {
    const nuevoArray: ObjetoConPropiedades[] = [];

    arrayDeObjetos.forEach(objeto => {
        const objetoModificado: ObjetoConPropiedades = { ...objeto };

        ['Subtotal', 'Total', 'Total Pagado'].forEach(prop => {
            if (objetoModificado.hasOwnProperty(prop)) {
                let valorOriginal = objetoModificado[prop] as string;

                valorOriginal = extraerNumeroYLetras(valorOriginal);
                const matches = valorOriginal.match(/([\d,]+)\s([A-Z]+)/);

                if (matches) {
                    const cantidad = parseFloat(matches[1].replace(',', ''));
                    const codigoMoneda = matches[2];

                    objetoModificado[prop] = cantidad;
                    objetoModificado[`${prop}-moneda`] = codigoMoneda;
                }
            }
        });

        nuevoArray.push(objetoModificado);
    });

    return nuevoArray;
}


function extraerNumeroYLetras(valor: string) {
    // Extraer solo dígitos y comas antes de convertir a número
    const soloDigitos = valor.match(/[\d,]+/g);
    // Unir los dígitos y reemplazar las comas antes de convertir a número
    const valorLimpio = soloDigitos ? soloDigitos.join('').replace(',', '.') : '';
    // Conservar las letras al final
    const letras = valor.replace(/[.\d, ]+/g, '').trim();
    // Convertir a número
    const numero = parseFloat(valorLimpio);
    return `${numero} ${letras}`;
}


interface Total {
    currency: string;
    amount: number;
}





