import {
    FaCheck,
    FaCheckDouble,
    FaMagnifyingGlass,
    FaRegCalendarCheck,
    FaToggleOn,
} from "react-icons/fa6";
import { MdDateRange } from "react-icons/md";
import { HiX } from "react-icons/hi";
import { useContext, memo } from "react";
import { FilterOpts, TableContext } from "../misc/GenericTable";
import { filter_types } from "../../interfaces/ServerInterfaces";

// Optimización de getFilterIcon usando un objeto de mapeo
const filterIcons: Record<filter_types, JSX.Element> = {
    boolean: <FaToggleOn />,
    input: <FaMagnifyingGlass />,
    select: <FaCheck />,
    multiselect: <FaCheckDouble />,
    datepicker: <FaRegCalendarCheck />,
    "price-range": <></>,
    "datepicker-range": <MdDateRange />,
};

export const getFilterIcon = (type: filter_types) => filterIcons[type] ?? <></>;

interface FilterComponentInt {
    availableFilters: FilterOpts[];
    filterAction: Function;
}

// Función auxiliar para encontrar un filtro específico
const findFilterItem = (filter: any, availableFilters: FilterOpts[]) => {
    return availableFilters.find(item => {
        if (item.format === "datepicker-range") {
            return item?.datepickerRange?.some(newItem => newItem?.filterCode === filter.id);
        } else if (item.format === "price-range") {
            return item?.priceRange?.some(price => price?.filterCode === filter.id);
        } else {
            return item.filterCode === filter.id;
        }
    });
};

// Componente optimizado
const FilterBadge = memo(({ filterComponent }: { filterComponent: FilterComponentInt | undefined }) => {
    const { selectedFilter, setSelectedFilter } = useContext(TableContext);

    const removeFilter = (idx: number) => {
        const newFilter = [...selectedFilter!];
        newFilter.splice(idx, 1);
        setSelectedFilter!(newFilter);
        filterComponent?.filterAction(newFilter.reduce((acc, { id, name }) => {
            acc[id] = name;
            return acc;
        }, {} as Record<string, any>));
    };

    return (
        <div className="flex flex-wrap gap-2 p-2">
            {selectedFilter?.map((filter, idx) => {
                const findedItem = findFilterItem(filter, filterComponent?.availableFilters || []);
                const dateRangeName = findedItem?.datepickerRange?.find(fitem => fitem.filterCode === filter.id)?.name || "";
                const priceRangeName = findedItem?.priceRange?.find(price => price.filterCode === filter.id)?.name || "";

                return (
                    <div
                        key={idx}
                        className="group relative inline-flex items-center gap-1 pl-5 pr-8 py-1 font-semibold italic bg-slate-500 rounded-full text-xs text-white"
                    >
                        {getFilterIcon(filter?.description! as filter_types ?? "")}
                        <p>{`${findedItem?.name ?? ""} ${dateRangeName} ${priceRangeName}`}</p>
                        <HiX
                            className="hidden group-hover:block absolute right-2 text-lg cursor-pointer"
                            onClick={() => removeFilter(idx)}
                        />
                    </div>
                );
            })}
        </div>
    );
});

export default FilterBadge;
