import { useState } from "react";
import Modal from "../../../../components/misc/GenericModal";
import { FieldValues, SubmitHandler } from "react-hook-form";
import useServerEcoCycle from "../../../../api/useServerEconomicCycle";
import SearchCriteriaComponent, {
  BasicTypeFilter,
  DateTypeFilter,
  SelectTypeFilter,
} from "../../../../components/misc/SearchCriteriaComponent";
import SalesByOrdersWithMessagingReport from "../report/SalesByOrdersWithMessagingReport";
import LoadingSpin from "../../../../components/misc/LoadingSpin";
import PdfAndExcelMessagingIncomes from "../pdfsandExcel/PdfAndExcelMessagingIncomes";
// import { useAppSelector } from "../../../../store/hooks";

function MessagingIncomesModal() {

  // const { deliverymans } = useAppSelector(state => state.nomenclator)

  const { getAllSalesbyOrdersWithMessaging, salesbyOrdersWithMessaging, isLoading, updateAllOrderState } = useServerEcoCycle();
  const dataAccess = { getAllSalesbyOrdersWithMessaging, salesbyOrdersWithMessaging, isLoading, updateAllOrderState };

  const [showReportDataModal, setShowReportDataModal] = useState<
    boolean | null
  >();

  const onSubmit: SubmitHandler<any> = async (data) => {
    getAllSalesbyOrdersWithMessaging(data);
    setShowReportDataModal(true);
  };

  //Management filters ------------------------------------------------------------------------

  const availableFilters: (
    | BasicTypeFilter
    | DateTypeFilter
    | SelectTypeFilter
  )[] = [];

  availableFilters.push(
    //Rango de fechas
    {
      name: "dateRange",
      isRequired: true,
      label: "Rango de fechas",
      type: "datepicker-range-including-time",
      datepickerRange: [
        {
          name: "dateFrom",
          label: "Desde",
          isUnitlToday: true,
        },
        {
          name: "dateTo",
          label: "Hasta",
          isUnitlToday: true,
        },
      ],
    },
  );

  return (
    <>
      <div>
        <h2 className="text-xl font-semibold mb-6">Ingresos por mensajería</h2>
        <div>
          <SearchCriteriaComponent
            filterAction={(data: FieldValues) => onSubmit(data)}
            filters={availableFilters}
            loading={isLoading}
            disableCriteriaSearch={true}
          />
        </div>
      </div>

      {!!showReportDataModal && (
        <Modal
          state={!!showReportDataModal}
          close={() => setShowReportDataModal(null)}
          size="l"
        >

          <h2 className="text-xl font-semibold mb-6">Ingresos por mensajería</h2>
          {
            isLoading ?
              <div className="flex w-full justify-center items-center">
                <LoadingSpin color="black" />
              </div>
              :
              <section className="grid grid-cols-12">
                {/* <SideNav tabs={tabs} action={setCurrent} className="col-span-2" /> */}
                <section className="overflow-auto scrollbar-thin col-span-12 px-5">
                  <div className="flex justify-end items-center w-full">
                    <PdfAndExcelMessagingIncomes
                      dataAccess={dataAccess}
                      show={showReportDataModal}
                    />
                  </div>


                  {!isLoading && (
                    <SalesByOrdersWithMessagingReport
                      dataAccess={dataAccess}
                      show={showReportDataModal}
                    />
                  )}
                </section>
              </section>
          }
        </Modal>
      )}
    </>
  );
}

export default MessagingIncomesModal;
