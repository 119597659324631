import React, { useContext } from "react";
import TextArea from "../../../components/forms/TextArea";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import { FaArrowRotateRight } from "react-icons/fa6";
import { SubmitHandler, useForm } from "react-hook-form";
import { DetailProductContext } from "../DetailProductContainer";

const Labels = () => {
  const { product, updateProduct } = useContext(DetailProductContext);

  const { handleSubmit, control } = useForm();

  const onSubmit: SubmitHandler<Record<string, any>> = (data) => {
    const { printableLabelFront, printableLabelBack, expirationInDaysAfterProduction } = data;

    const dataToSend = {
      printableLabelFront,
      printableLabelBack,
      expirationInDaysAfterProduction,
    };

    updateProduct!(product?.id, dataToSend);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid grid-cols-1 place-content-between"
    >
      <div className="p-7 flex flex-col border border-slate-300 rounded-md h-auto justify-start items-start gap-2">
        <div className="w-full">
          <TextArea
            name="printableLabelFront"
            control={control}
            defaultValue={product?.printableLabelFront}
            label="Etiqueta andverso"
          />
        </div>
        <div className="w-full">
          <TextArea
            name="printableLabelBack"
            control={control}
            defaultValue={product?.printableLabelBack}
            label="Etiqueta reverso"
          />
        </div>

        <div className="w-full">
          <Input
            name="expirationInDaysAfterProduction"
            label="Al producir, extender su expiración en (días)"
            control={control}
            type="number"
            rules={{ required: "* Campo requerido" }}
            defaultValue={product?.expirationInDaysAfterProduction}
            placeholder="Ejemplo: 7"
          />
        </div>
      </div>

      <div className=" flex justify-end py-4">
        <Button
          icon={<FaArrowRotateRight className={`h-5 `} />}
          name="Actualizar"
          color="slate-600"
          type="submit"
        />
      </div>
    </form>
  );
};

export default Labels;
