import { useAppSelector } from "../../../store/hooks";
import useServerBusiness from "../../../api/useServerBusiness";
import {
  TagIcon,
  ArrowPathRoundedSquareIcon,
  PresentationChartBarIcon,
  RectangleGroupIcon,
  TruckIcon,
  ClipboardDocumentCheckIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  UserGroupIcon,
  ClipboardDocumentListIcon,
  CalendarIcon,
} from "@heroicons/react/20/solid";
import { AiOutlineFire } from "react-icons/ai";
import { useState } from "react";
import SpinnerLoading from "../../../components/misc/SpinnerLoading";
import { Tooltip } from "react-tooltip";
import Modal from "../../../components/misc/GenericModal";
import AlertContainer from "../../../components/misc/AlertContainer";
import { FaCrown } from "react-icons/fa6";
import { BriefcaseIcon, BuildingStorefrontIcon } from "@heroicons/react/24/outline";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}


const manageIcon = (code: string) => {
  switch (code) {
    case "module_accounts":
      return CreditCardIcon;

    case "module_duplicator":
      return DocumentDuplicateIcon;

    case "module_production":
      return AiOutlineFire;

    case "module_human_resources":
      return UserGroupIcon;

    case "module_billing":
      return ClipboardDocumentListIcon;

    case "module_booking":
      return CalendarIcon;

    case "visual_online_shop":
      return BuildingStorefrontIcon;

    case "visual_economic_cycles":
      return ArrowPathRoundedSquareIcon;

    case "M_INVENTORY":
      return RectangleGroupIcon;

    case "M_POSTER_MARKETING":
      return PresentationChartBarIcon;

    case "visual_suppliers":
      return TruckIcon;

    case "visual_customers":
      return BriefcaseIcon;

    case "visual_dispatches":
      return RectangleGroupIcon;

    case "moduleAccount":
      return CreditCardIcon;

    case "F_BATCHES":
      return ClipboardDocumentCheckIcon;

    default:
      return TagIcon;
  }
};

const NonSensiblePackages = [
  "visual_dispatches",
  "visual_economic_cycles",
  "visual_customers",
  "visual_suppliers",
  "visual_online_shop",
  "visual_human_resources",
];

const Visual = () => {
  const { configurationsKey } = useAppSelector((state) => state.init.business!);
  const { updateConfigs, isFetching } = useServerBusiness();

  const [desactivate, setDesactivate] = useState<{
    state: boolean;
    data: any;
    isActivation: boolean | null;
  }>({
    state: false,
    data: null,
    isActivation: null,
  });

  const [packagesServices, setPackagesServices] = useState(
    [
      {
        code: "visual_dispatches",
        name: "Despachos",
        isActive: configurationsKey.find(
          (item) => item.key === "visual_dispatches"
        )?.value === "true"
      },
      {
        code: "visual_economic_cycles",
        name: "Ciclos económicos",
        isActive: configurationsKey.find(
          (item) => item.key === "visual_economic_cycles"
        )?.value === "true"
      },
      {
        code: "visual_customers",
        name: "Clientes",
        isActive: configurationsKey.find(
          (item) => item.key === "visual_customers"
        )?.value === "true"
      },
      {
        code: "visual_suppliers",
        name: "Proveedores",
        isActive: configurationsKey.find(
          (item) => item.key === "visual_suppliers"
        )?.value === "true"
      },
      {
        code: "visual_online_shop",
        name: "Tienda online",
        isActive: configurationsKey.find(
          (item) => item.key === "visual_online_shop"
        )?.value === "true"
      },
      {
        code: "moduleAccount",
        name: "Cuentas bancarias",
        isActive: configurationsKey.find(
          (item) => item.key === "moduleAccount"
        )?.value === "true"
      },
      {
        code: "module_booking",
        name: "Reservaciones",
        isActive: configurationsKey.find(
          (item) => item.key === "module_booking"
        )?.value === "true"
      },
      {
        code: "module_production",
        name: "Producción",
        isActive: configurationsKey.find(
          (item) => item.key === "module_production"
        )?.value === "true"
      },
      {
        code: "module_human_resources",
        name: "Capital humano",
        isActive: configurationsKey.find(
          (item) => item.key === "module_human_resources"
        )?.value === "true"
      },
      {
        code: "module_billing",
        name: "Facturación",
        isActive: configurationsKey.find(
          (item) => item.key === "module_billing"
        )?.value === "true"
      },
      {
        code: "module_duplicator",
        name: "Planificador",
        isActive: configurationsKey.find(
          (item) => item.key === "module_duplicator"
        )?.value === "true"
      },
    ].map((mod_fun) => {
      return {
        id: mod_fun.code,
        name: mod_fun.name,
        icon: manageIcon(mod_fun.code),
        active: mod_fun?.isActive,
      };
    })
  );


  return (
    <div className="relative px-4">
      {isFetching && (
        <div className="absolute w-full h-full z-20 flex justify-center items-center px-4 bg-slate-200 bg-opacity-30 ">
          <SpinnerLoading />
        </div>
      )}

      {/* //Modulos */}
      <dl className="mt-2 mb-5 grid grid-cols-2 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {packagesServices
          .map((item) => (
            <div
              data-tooltip-id={
                (!NonSensiblePackages.includes(item.id) &&
                  !item.active) ?
                  "my-tooltip" : ""
              }
              key={item.id}
              className="relative overflow-hidden rounded-lg bg-white px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6"
            >
              <dt>
                <div className="absolute rounded-md bg-slate-500 p-3">
                  <item.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-white"
                  />
                </div>
                <p className="ml-16  text-sm font-medium text-gray-500">
                  {item.name}
                </p>
              </dt>
              <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
                {/* <p className="text-2xl font-semibold text-gray-900">{item.stat}</p> */}
                <p
                  className={classNames(
                    item.active ? "text-green-600" : "text-red-600",
                    "ml-2 flex items-baseline text-sm font-semibold"
                  )}
                >
                  {item.active ? (
                    <ShieldCheckIcon
                      aria-hidden="true"
                      className="h-10 w-5 flex-shrink-0 self-center text-green-500"
                    />
                  ) : (
                    <ShieldExclamationIcon
                      aria-hidden="true"
                      className="h-10 w-5 flex-shrink-0 self-center text-yellow-500"
                    />
                  )}

                  <span className="sr-only">
                    {" "}
                    {item.active ? "Increased" : "Decreased"} by{" "}
                  </span>
                  {/* {item.change} */}
                </p>

                <div
                  className={`absolute inset-x-0 bottom-0 ${!item.active ? "bg-gray-50" : "bg-gray-50"
                    }  px-4 py-4 sm:px-6`}
                >
                  {NonSensiblePackages.includes(item.id) ? (
                    !item.active ? (
                      <div className="text-sm text-end">
                        <button
                          disabled={isFetching}
                          className="font-medium text-indigo-600 hover:text-indigo-500 text-end"
                          onClick={() => {
                            setDesactivate({
                              state: true,
                              data: item,
                              isActivation: true,
                            });
                          }}
                        >
                          Activar{" "}
                          <span className="sr-only"> {item.name} stats</span>
                        </button>
                      </div>
                    ) : (
                      <div className="text-sm text-end">
                        <button
                          disabled={isFetching}
                          className="font-medium text-red-600 hover:text-red-500 text-end"
                          onClick={() => {
                            setDesactivate({
                              state: true,
                              data: item,
                              isActivation: false,
                            });
                          }}
                        >
                          Desactivar{" "}
                          <span className="sr-only"> {item.name} stats</span>
                        </button>
                      </div>
                    )
                  ) : (
                    <div className="w-full flex justify-end">
                      <FaCrown
                        aria-hidden="true"
                        className="h-6 w-6 text-yellow-500 "
                      />
                    </div>
                  )}
                </div>
              </dd>
            </div>
          ))}
      </dl>

      <Tooltip
        id="my-tooltip"
        content={
          "Este módulo está sujeto a pago, por favor contacte al equipo de comercial para activarlo."
        }
        events={["hover"]}
      />

      {desactivate?.state && (
        <Modal state={desactivate.state} close={() => {
          setDesactivate({
            state: false,
            data: null,
            isActivation: null,
          });
        }}>
          <AlertContainer
            title={`${desactivate.isActivation ? "Activar" : "Desactivar"} ${desactivate?.data.name
              }`}
            onCancel={setDesactivate}
            text="¿Seguro que desea continuar?"
            onAction={() => {


              if (desactivate.isActivation) {
                updateConfigs({ [desactivate.data.id]: true })

                setPackagesServices(packagesServices.map((item) => {
                  if (item.id === desactivate.data.id) {
                    return {
                      ...item,
                      active: true
                    }
                  } else {
                    return item
                  }
                }))

              } else {
                updateConfigs({ [desactivate.data.id]: false })
                setPackagesServices(packagesServices.map((item) => {
                  if (item.id === desactivate.data.id) {
                    return {
                      ...item,
                      active: false
                    }
                  } else {
                    return item
                  }
                }))
              }

              setDesactivate({
                state: false,
                data: null,
                isActivation: null,
              });
            }}
            loading={isFetching}
          />
        </Modal>
      )}
    </div>
  );
};

export default Visual;
