import React from "react";

const UnlimitedBadge = () => {
  return (
    <div
      className={
        "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0 bg-blue-100 text-blue-800"
      }
    >
      Ilimitado
    </div>
  );
};

export default UnlimitedBadge;
