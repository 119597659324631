import React, { useState } from 'react'
import { TabsAttr } from '../../../components/misc/SideNav';
import TabNav from '../../../components/navigation/TabNav';
import Processed from './Processed';
import Labels from './Labels';

const ProcessedAndLabels = () => {

    const [currentTab, setCurrentTab] = useState("proc");

    const tabs: TabsAttr[] = [
        {
          name: "Áreas de procesado",
          href: "proc",
          current: currentTab === "proc",
        },
        {
          name: "Etiquetas",
          href: "labels",
          current: currentTab === "labels",
        },
      ];

  return (
    <div>
        <TabNav action={(tab: string) => setCurrentTab(tab)} tabs={tabs} />

        {currentTab === "proc" && <Processed />}
        {currentTab === "labels" && <Labels />}
    </div>
  )
}

export default ProcessedAndLabels
